import { keyframes } from 'styled-components';


export const cycleGradient = keyframes`
  from {
    transform: translateY(-90%);
  }

  to {
    transform: translateY(0);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const shake = keyframes`
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
`;

export const slideIn = keyframes`
  0% { width: 0 }
  20% { width: 0 }
  100% { width: 100% }
`;
