import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

import { CLOSE_NAV } from '../state/actionTypes';
import { buttonStyles, underlineStyles } from '../constants/styles';
import { createLocalisedURI } from '../helpers/location';


export const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  text-decoration: none;
`;

export const UnderlinedLink = styled(StyledLink)`
  ${underlineStyles}
`;

export const ButtonLink = styled(StyledLink)`
  ${buttonStyles}
`;

const LocalisedLink = ({ children, closeNav, hasUnderline, isButton, to, intl: { locale } }) => {
  const uri = createLocalisedURI({ locale, to });
  let component = StyledLink;
  if (isButton) {
    component = ButtonLink;
  } else if (hasUnderline) {
    component = UnderlinedLink;
  }

  return React.createElement(component, { onClick: closeNav, to: uri }, children);
};

LocalisedLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  closeNav: PropTypes.func,
  hasUnderline: PropTypes.bool,
  intl: intlShape.isRequired,
  isButton: PropTypes.bool,
};

LocalisedLink.defaultProps = {
  hasUnderline: true,
  isButton: false,
};

const mapDispatchToProps = (dispatch) => ({
  closeNav: () => dispatch({ type: CLOSE_NAV }),
});

export const LocaleLink = LocalisedLink;

export default injectIntl(connect(null, mapDispatchToProps)(LocalisedLink));
