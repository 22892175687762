import PropTypes from 'prop-types';

import { viewportWidth } from './constants/mediaqueries';


const {
  number,
  string,
} = PropTypes;

export const fluidPropTypes = PropTypes.shape({
  aspectRatio: number.isRequired,
  base64: string.isRequired,
  sizes: string.isRequired,
  src: string.isRequired,
  srcSet: string.isRequired,
});

const breakpoints = ['xxs'].concat(Object.keys(viewportWidth));

export const windowSizeTypes = PropTypes.oneOf(breakpoints);
