// Fallback for browsers that don't have full <dialog> support
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog

const hasDialogSupport = typeof HTMLDialogElement === 'function';

export const showDialog = (dialog) => {
  if (hasDialogSupport) {
    dialog.show();
  } else {
    dialog.setAttribute('open', '');
  }
};

export const closeDialog = (dialog) => {
  if (hasDialogSupport) {
    dialog.close();
  } else {
    dialog.removeAttribute('open');
  }
};
