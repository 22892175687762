export const SPACING_SIDE_MOBILE = '2.5rem';
export const SPACING_SIDE_MOBILE_HALF = '1.25rem';
export const SPACING_NAV_SIDE_DESKTOP = '2vw';

export const SPACING_VERTICAL = {
  l: 'margin-bottom: 9.375rem',
  s: 'margin-bottom: 4.6875rem',
};

export const SPACING_VERTICAL_HEADINGS = {
  l: 'margin-bottom: 6.25rem',
  s: 'margin-bottom: 3.125rem',
};
