import { COLOR_BLACK } from './colors';
import { slideIn } from './keyframes';
import { breakpoints } from './mediaqueries';
import { typeSizes } from './typography';


export const fontStyles = {
  display: typeSizes.xxl,
  title: typeSizes.xl,
  subtitle: typeSizes.l,
  body: typeSizes.m,
  nav: typeSizes.sm,
  bodySmall: typeSizes.s,
  legal: typeSizes.s,
  footer: typeSizes.s,
  checkout: typeSizes.xs,
  uppercase: `
    ${typeSizes.s}
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  `,
  checkoutUppercase: `
    ${typeSizes.xs}
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  `,
};

export const buttonBorderWidth = '1px';
export const verticalButtonPadding = '0.438rem';
export const verticalButtonPaddingLarge = '0.625rem';

export const buttonStyles = `
  ${typeSizes.s}
  padding: ${verticalButtonPadding} 0.75rem; /* 7/16 12/16 */
  border: ${buttonBorderWidth} solid ${COLOR_BLACK};
  border-radius: 0.375rem; /* 6/16 */

  :not([disabled]) {
    cursor: pointer;
  }

  @media ${breakpoints.l} {
    padding: ${verticalButtonPaddingLarge} 1rem; /* 10/16 16/16 */
    border-radius: 0.5rem; /* 8/16 */
  }
`;

export const underlineStyles = `
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    top: calc(50% + .7em);
    width: 100%;
    background-color: ${COLOR_BLACK};
  }
  &:hover::before {
    animation-name: ${slideIn};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
`;

export const underlinedButtonStyles = `
  padding: 0;
  border: 0;
  background: none;
  ${underlineStyles}
`;

export const linkStyles = `
  display: inline-block;
  position: relative;
  color: ${COLOR_BLACK};
  text-decoration: none;
  ${underlineStyles}
`;

export const bulletStyles = `
  position: relative;
  padding-left: 1.25em;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${COLOR_BLACK};
    transform: translateY(0.5em);
  }
`;
