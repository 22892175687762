export function isXLargeViewport(size) {
  return size === 'xl';
}

export function isLargeViewport(size) {
  return size === 'l';
}

export function isMediumViewport(size) {
  return size === 'm';
}

export function isSmallViewport(size) {
  return size === 's';
}

export function isXSmallViewport(size) {
  return size === 'xs';
}

export function isTinyViewport(size) {
  return size === 'xxs';
}
