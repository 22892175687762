import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buttonStyles, underlineStyles } from '../constants/styles';
import { isMediaURI } from '../helpers/location';


const ExternalLink = styled.a.attrs({
  rel: (props) => (!isMediaURI(props.href) ? 'noopener noreferrer' : undefined),
  target: (props) => (!isMediaURI(props.href) ? '_blank' : undefined),
})`
  ${(props) => (props.isButton && buttonStyles)};
  ${(props) => (!props.isButton && props.hasUnderline && underlineStyles)};
`;

ExternalLink.propTypes = {
  hasUnderline: PropTypes.bool,
  isButton: PropTypes.bool,
};

ExternalLink.defaultProps = {
  hasUnderline: true,
};

export default ExternalLink;
