import React from 'react';
import Markdown from 'react-markdown';
import breaks from 'remark-breaks';

import ExternalLink from '../components/ExternalLink';
import LocalisedLink from '../components/LocalisedLink';

import { canUseDOM } from './dom';

import { createLocalisedURI, getLocaleFromURI, isInternalURI } from './location';


const RouterLink = (c) => {
  if (isInternalURI(c.href)) {
    return <LocalisedLink to={c.href}>{c.children}</LocalisedLink>;
  }
  return <ExternalLink href={c.href}>{c.children}</ExternalLink>;
};

const CheckoutRouterLink = (c) => {
  let uri = c.href;
  if (canUseDOM && isInternalURI(uri)) {
    const locale = getLocaleFromURI(window.location.pathname);
    const path = createLocalisedURI({ locale, to: uri });
    uri = `${window.location.origin}${path}`;
  }
  return <ExternalLink href={uri}>{c.children}</ExternalLink>;
};

export const customMarkdown = (content) => (
  <Markdown
    plugins={[breaks]}
    renderers={{ link: RouterLink }}
    source={content}
  />
);

export const customMarkdownCheckout = (content) => (
  <Markdown
    plugins={[breaks]}
    renderers={{ link: CheckoutRouterLink }}
    source={content}
  />
);
