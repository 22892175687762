import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { COLOR_BLACK } from '../constants/colors';
import { SPACING_SIDE_MOBILE, SPACING_NAV_SIDE_DESKTOP } from '../constants/spacings';
import { breakpoints } from '../constants/mediaqueries';
import { fontStyles } from '../constants/styles';
import { isLargeViewport, isXLargeViewport } from '../helpers/window';
import { windowSizeTypes } from '../types';

import LocalisedLink from './LocalisedLink';


const Wrapper = styled.header`
  position: absolute;
  top: calc(${SPACING_SIDE_MOBILE} / 2); /* 30/16 */
  left: 0;
  width: 100%
  z-index: 2;

  a {
    text-decoration: none;
    color: ${COLOR_BLACK};
  }
`;

const Title = styled.h1`
  position: absolute;
  left: 50%;
  ${fontStyles.uppercase}
  transform: translateX(-50%);
`;

const BookingLink = styled.div`
  position: absolute;
  top: 0;
  right: calc(${SPACING_SIDE_MOBILE} / 2);
  ${fontStyles.bodySmall}

  @media ${breakpoints.l} {
    position: fixed;
    top: calc(${SPACING_SIDE_MOBILE} / 2); /* 30/16 */
    right: ${SPACING_NAV_SIDE_DESKTOP};
  }
`;

const Header = ({ bookingPath, hideBookingLink, siteTitle, windowSize }) => {
  const isLarge = isLargeViewport(windowSize) || isXLargeViewport(windowSize);

  return (
    <Wrapper>
      <Title>
        <LocalisedLink hasUnderline={false} to="/">{siteTitle}</LocalisedLink>
      </Title>
      <If condition={!hideBookingLink}>
        <BookingLink>
          <LocalisedLink hasUnderline={false} to={bookingPath}>
            <FormattedMessage id={isLarge ? 'booking.bookingButton' : 'booking.bookingButtonShort'} />
          </LocalisedLink>
        </BookingLink>
      </If>
    </Wrapper>
  );
};

Header.propTypes = {
  bookingPath: PropTypes.string,
  hideBookingLink: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  siteTitle: PropTypes.string.isRequired,
  windowSize: windowSizeTypes,
};

export default Header;
